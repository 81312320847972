
.logo {
  width: 36px;
  height: 23px;
}


.logoGuarnic {
  width: 45px;
  height: 45px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  gap: 15px;
}